import { LinearProgress, Box } from '@mui/material';
import { Suspense, useEffect, useState, useRef } from 'react';

import useCanvas from './useCanvas'

import { getIsHorizontal, getAislePadding, getAisleMeasures, getTopAisleCoords } from '../utils/converters';

const RADIUSPLUS = 4,
	MAXRADIUS = 8,
	MAXALERTCONST = 20,
	INTERVALAISLE = 3;

const alterColor = (px, isDisabled) => {
	if (px === 205) { //fill
		return isDisabled ? [203, 203, 216, 245] : [196, 196, 217, 255]
	} else if (px <= 100) { // borders
		return isDisabled ? [26, 36, 45, 50] : [26, 36, 45, 100];
	} else {
		return isDisabled ? [px, px, px, 205] : [px, px, px, 255];
	}
}

const storeMapDrawer = (ctx, pixelMatrix, width, height, isDisabled = false) => {
	let GREYCOUNTER = 0;
	ctx.clearRect(0, 0, width, height);

	// only do this once per page
	const imageData = ctx.createImageData(width, height);
	let imageIndex = 0;
	for (var canvasHeight = 0; canvasHeight < height; canvasHeight++) {
		const row = pixelMatrix[canvasHeight]
		for (let x = 0; x < width; x++) {
			const px = row[x];
			// alter RGBA
			[imageData.data[imageIndex], imageData.data[imageIndex + 1], imageData.data[imageIndex + 2], imageData.data[imageIndex + 3]] = alterColor(px, isDisabled);
			imageIndex += 4;
			if (px === 205) { GREYCOUNTER++ } else { GREYCOUNTER = 0 }
			if (GREYCOUNTER === width * 7 && canvasHeight > width / 2) break // to cut extended gray maps by down
		}
		if (GREYCOUNTER === width * 7 && canvasHeight > width / 2) break // to cut extended gray maps by down
	}
	// to cut extended gray maps by down && change canvasHeight's for from let to var type
	ctx.putImageData(imageData, 0, 0);
	// TODO: handle a loading when the user change the map
	// isCanvasLoading = false;
	return canvasHeight;
}

const writeAisleOnCanvas = (ctx, positionsDict, aislesClientNames, scaleValue, isTextOnMiddle = false, canJoinAisleName = true) => {
	const auxSet = new Set();
	Object.entries(positionsDict).forEach(positionName => {
		const aisleName = canJoinAisleName ? positionName[0].split('-')[0] : positionName[0],
			postionObject = positionName[1],
			clientAisleName = aislesClientNames[aisleName] ? aislesClientNames[aisleName] : aisleName;

		// const aisleMappedInfo = aislesMapped[aisleCode];

		if (!auxSet.has(aisleName)) {
			ctx.save();
			const aislePartPosition = 2;
			const x_i = postionObject.px_init[0] * scaleValue[0],
				y_i = postionObject.px_init[1] * scaleValue[1];
			let x_e = postionObject.px_end[0] * scaleValue[0],
				y_e = postionObject.px_end[1] * scaleValue[1];
			const distance_x = x_e - x_i, distance_y = y_e - y_i;
			// updtaes the position of the text to be in the middle of the aisle
			x_e = distance_y > distance_x ? x_i : x_e;
			y_e = distance_y > distance_x ? y_e : y_i;

			let rotation, translationX, translationY;
			if (isTextOnMiddle) {
				rotation = distance_y > distance_x ? Math.PI / 2 : 0;
				translationX = x_i - 1 + distance_x / aislePartPosition;
				translationY = y_i + distance_y / aislePartPosition;
			} else {
				rotation = x_e ? Math.atan(distance_y / distance_x) : Math.PI / 2;
				translationX = x_i + distance_x / aislePartPosition;
				translationY = y_i + distance_y / aislePartPosition;
			}

			ctx.font = 'bold 10px verdana, sans-serif ';
			ctx.translate(translationX, translationY);
			ctx.rotate(rotation);
			ctx.translate(-translationX, -translationY);
			ctx.fillStyle = '#1a242d';
			ctx.textAlign = 'center';

			ctx.fillText(clientAisleName, translationX, translationY);
			ctx.restore();
			auxSet.add(aisleName);
		}
	});
}

const categoryTextDrawer = (ctx, categoriesOnMap) => {
	const mapAux = [];
	const categoriesList = Object.values(categoriesOnMap).filter((row) => row.map_point).sort((a, b) => a.map_point[1] - b.map_point[1]);
	categoriesList.forEach((categoryInfo) => {
		if (categoryInfo.map_point) {
			let y = categoryInfo.map_point[1];
			const x = categoryInfo.map_point[0],
				categoryName = categoryInfo.name,
				categoryNameLength = categoryName.length * 2.5;

			const isColinded = mapAux.findIndex(row => { return (row[0] - row[2]) < (x + categoryNameLength) && (x - categoryNameLength) < (row[0] + row[2]) && row[1] - 11 <= y && y < row[1] + 11 });
			if (isColinded !== -1) {
				y = y + 12 - (y - mapAux[isColinded][1]);
				mapAux[isColinded] = [x, y, categoryNameLength, categoryName];
			} else {
				mapAux.push([x, y, categoryNameLength, categoryName]);
			}

			//CANVAS WRITING
			ctx.save();
			ctx.font = "bold 10px verdana, sans-serif ";
			ctx.fillStyle = "#1a242d";
			ctx.textAlign = "center";
			ctx.fillText(categoryName, x, y);
			ctx.restore();
		}
	});
}

const CanvasDrawOnCircle = (ctx, pixel, radius = 3, ColorFill = 'rgba(254, 45, 56, 0.4)') => {
	const px = pixel[0], py = pixel[1];
	ctx.beginPath();
	ctx.arc(px, py, radius, 0, 2 * Math.PI);
	ctx.fillStyle = ColorFill;
	ctx.fill();
}

const drawLineWithWidth = (ctx, x1, y1, x2, y2, width, colorFill = 'rgba(255, 164, 0, 0.4)', strokeStyle = "#212529") => {
	// Calculate the direction vector of the line
	const dx = x2 - x1;
	const dy = y2 - y1;

	// Calculate the length of the line
	const length = Math.sqrt(dx * dx + dy * dy);

	// Normalize the direction vector
	const ux = dx / length;
	const uy = dy / length;

	// Calculate the perpendicular vector to the line
	const px = -uy * width / 2;
	const py = ux * width / 2;

	// Calculate the four corners of the rectangle
	const x1Left = x1 + px;
	const y1Left = y1 + py;
	const x1Right = x1 - px;
	const y1Right = y1 - py;

	const x2Left = x2 + px;
	const y2Left = y2 + py;
	const x2Right = x2 - px;
	const y2Right = y2 - py;

	// Draw the rectangle on the canvas
	ctx.beginPath();
	ctx.fillStyle = colorFill;
	ctx.moveTo(x1Left, y1Left);
	ctx.strokeStyle = strokeStyle;
	ctx.lineTo(x2Left, y2Left);
	ctx.lineTo(x2Right, y2Right);
	ctx.lineTo(x1Right, y1Right);
	ctx.closePath();
	ctx.fill();
};

const ObstaclesDrawer = (ctx, scaleValue, obstacles, rotated) => {
	obstacles.forEach((obs) => {
		let x1, y1, x2, y2;
		const width = 3;
		const colorFill = "rgba(253, 99, 3, 0.96)"

		if (rotated) {
			y1 = obs.px_initial[0] * scaleValue[1];
			x1 = obs.px_initial[1] * scaleValue[0];
			y2 = obs.px_final[0] * scaleValue[1];
			x2 = obs.px_final[1] * scaleValue[0];
		} else {
			x1 = obs.px_initial[0] * scaleValue[0];
			y1 = obs.px_initial[1] * scaleValue[1];
			x2 = obs.px_final[0] * scaleValue[0];
			y2 = obs.px_final[1] * scaleValue[1];
		}

		drawLineWithWidth(ctx, x1, y1, x2, y2, width, colorFill);
	})
}

const PosesDrawer = (ctx, scaleValue, selectedDots, rotated) => {
	selectedDots.forEach((pose) => {
		let x, y, pixel, colorFill;
		let r, g, b;	
		const radius = 0.8;

		if (pose.mode === 'waf') {
			r = 255 * (1 - pose.loc_conf);
			g = 0;
			b = 255 * pose.loc_conf;
		} else {
			r = 255 * (1 - pose.loc_conf);
			g = 255 * pose.loc_conf;
			b = 0;
		}
		
		colorFill = `rgba(${r}, ${g}, ${b}, 0.6)`;

		if (rotated) {
			x = pose.x * scaleValue[1];
			y = pose.y * scaleValue[0];
			pixel = [y, x];
		} else {
			x = pose.x * scaleValue[0];
			y = pose.y * scaleValue[1];
			pixel = [x, y];
		}

		CanvasDrawOnCircle(ctx, pixel, radius, colorFill);
	});
}

const getGroupName = (element, groupingAlertsBy, tramo = null) => {
	if (groupingAlertsBy === 'landmarks') {
		return element.landmark;
	} else {
		return `${element.aisle_name}, tramo: ${tramo}`;
	}
}

const storeGroup = (dictGroup, element, groupingAlertsBy, tramo, taskDataGroupedDict, addTotalAlerts = 1, addCompleted = 1, LandmarkGroupList = null) => {
	const groupName = getGroupName(element, groupingAlertsBy, tramo);
	if (dictGroup[groupName]) {
		dictGroup[groupName].map_point_scaled[0] += element.map_point_scaled[0];
		dictGroup[groupName].map_point_scaled[1] += element.map_point_scaled[1];
		dictGroup[groupName].location_x += element.location_x;

		dictGroup[groupName].totalAlerts += addTotalAlerts;
		if (element.completed) dictGroup[groupName].completed += addCompleted;
		if (!LandmarkGroupList) { dictGroup[groupName].alertList.push(element) } else { dictGroup[groupName].alertList.push(...LandmarkGroupList) }
		if (!LandmarkGroupList) { taskDataGroupedDict[groupName].push(element) } else { taskDataGroupedDict[groupName].push(...LandmarkGroupList) }
	} else {
		dictGroup[groupName] = {
			groupName: groupName, map_point_scaled: element.map_point_scaled, location_x: element.location_x,
			totalAlerts: addTotalAlerts, landmark: element.landmark,
			aisle_name: element.aisle_name, tramo: tramo,
			completed: 0, alertList: []
		}
		if (element.completed) dictGroup[groupName].completed += addCompleted;
		if (!LandmarkGroupList) { dictGroup[groupName].alertList.push(element) } else { dictGroup[groupName].alertList.push(...LandmarkGroupList) }
		if (!LandmarkGroupList) { taskDataGroupedDict[groupName] = [element] } else { taskDataGroupedDict[groupName] = LandmarkGroupList }
	}
	return taskDataGroupedDict;
}

const sortGroup = (customGroup, groupingAlertsBy) => {
	if (groupingAlertsBy === 'landmarks') {
		return Object.values(customGroup).sort((a, b) => { return a.landmark.localeCompare(b.landmark, undefined, { numeric: true, sensitivity: 'base' }); });
	} else {
		return Object.values(customGroup).sort((a, b) => { return b.tramo > a.tramo ? -1 : 1 });
	}
}

const groupDataDrawer = (ctx, scaleValue, tasksData, groupingAlertsBy = 'custom') => {
	const landmarkIntervalAux = {};
	const linearGroup = {};
	// let completed = 0, totalTasks = 0;

	// let totalAlertProgress = 0;
	let maxAlert = MAXALERTCONST;

	const aisleLandmarkMap = {};
	const taskDataGroupedDict = {};

	for (let index = 0; index < tasksData?.length ?? 0; index++) {
		const row = tasksData[index];
		// Counting to get the percent of completed tasks
		// if (row.completed) completed++;
		// totalTasks++;

		// ignoring cooldown elements and alerts without points
		if (row.status === 'cooldown' || !row.map_point) { continue }

		row.map_point_scaled = [row.map_point[0] * scaleValue[0], row.map_point[1] * scaleValue[1]];

		const tramo = row.location_x ? Math.floor(row.location_x / INTERVALAISLE) + 1 : 0;
		if (row.landmark) {
			storeGroup(landmarkIntervalAux, row, groupingAlertsBy, tramo, taskDataGroupedDict);
		} else {
			storeGroup(linearGroup, row, groupingAlertsBy, tramo, taskDataGroupedDict);
		}
	}

	Object.keys(landmarkIntervalAux).forEach(landmarkName => {
		const landmarkValue = landmarkIntervalAux[landmarkName];
		const tramo = Math.floor(landmarkValue.location_x / (landmarkValue.totalAlerts * INTERVALAISLE)) + 1;
		storeGroup(linearGroup, landmarkValue, groupingAlertsBy, tramo, taskDataGroupedDict, landmarkValue.totalAlerts, landmarkValue.completed, landmarkIntervalAux[landmarkName].alertList);
		landmarkValue.groupName = getGroupName(landmarkValue, groupingAlertsBy, tramo);
	})

	// TODO: return completed task percent to show in the progress bar
	// TODO: AGREGAR LA T EN EL HTML!!! `${completed} ${t('CWS.digital_store.completeds')}` y retornar completed para hacerlo
	// totalAlertProgress = Math.round(1000 * completed / totalTasks) / 10;

	const customGroup = groupingAlertsBy === 'landmarks' ? landmarkIntervalAux : linearGroup;
	const taskDataGrouped = sortGroup(customGroup, groupingAlertsBy);
	maxAlert = [MAXALERTCONST, ...taskDataGrouped.map((row) => row.totalAlerts)].reduce((a, b) => a > b ? a : b);
	// setCirclesColors(maxAlert);

	for (let i = 0; i < taskDataGrouped.length; i++) {
		const GroupedElement = taskDataGrouped[i];
		let radius = GroupedElement.totalAlerts * (MAXRADIUS / maxAlert);

		// creating indexing aisle-landmark to moving on between landmarks inside aisle
		if (aisleLandmarkMap[GroupedElement.aisle_name]) { GroupedElement.index = aisleLandmarkMap[GroupedElement.aisle_name].push(i) - 1 } else { GroupedElement.index = 0; aisleLandmarkMap[GroupedElement.aisle_name] = [i] };

		radius = radius > MAXRADIUS ? MAXRADIUS : radius;
		const r = 254 - GroupedElement.totalAlerts * 3,
			g = 45 - GroupedElement.totalAlerts * 3,
			b = 56 - GroupedElement.totalAlerts * 3,
			a = 0.5 + (0.35 - 0.35 / GroupedElement.totalAlerts);

		let colorFill;
		if (GroupedElement.completed === GroupedElement.totalAlerts) {
			colorFill = 'rgba(71, 191, 131, 0.9)';
			GroupedElement.radius = radius = 0;
		} else {
			colorFill = `rgba(${r}, ${g}, ${b}, ${a})`;
			GroupedElement.radius = radius;
		}

		GroupedElement.map_point_scaled = GroupedElement.map_point_scaled.map(row => Math.floor(row / GroupedElement.totalAlerts))
		CanvasDrawOnCircle(ctx, GroupedElement.map_point_scaled, RADIUSPLUS + radius, colorFill);
	}

	return { taskDataGroupedDict, taskDataGrouped, aisleLandmarkMap }
}

const drawRectangleOnCanvas = (ctx, px, py, width, height, colorFill = 'rgba(255, 164, 0, 0.4)', strokeStyle = "#212529") => {
	ctx.beginPath();
	ctx.fillStyle = colorFill;
	ctx.fillRect(px, py, width, height);
	ctx.strokeStyle = strokeStyle;
	ctx.lineWidth = 0.9;
	ctx.rect(px, py, width, height);
	ctx.stroke();
}


const AislesDrawer = ({ ctx, aislesMapped, aislesClientNames, scaleValue, isDisabled = false, selectedAisle = null }) => {
	const aislesCoords = selectedAisle ? aislesMapped[selectedAisle] ? [aislesMapped[selectedAisle]] : [] : Object.values(aislesMapped);
	aislesCoords.forEach(aisleCoord => {
		const PADDING = getAislePadding();
		const upperLeftPx = aisleCoord.px_init.map((row, index) => row * scaleValue[index]);
		const lowerRightPx = aisleCoord.px_end.map((row, index) => row * scaleValue[index]);
		const ColorFill = isDisabled ? 'rgba(169,137, 78, 0.41)' : 'rgba(255, 164, 0, 0.4)';
		const strokeStyle = isDisabled ? 'rgba(169,137, 78, 0.8)' : 'rgba(255, 164, 0, 0.9)';
		drawLineWithWidth(ctx, upperLeftPx[0], upperLeftPx[1], lowerRightPx[0], lowerRightPx[1], PADDING, ColorFill, strokeStyle);
	});
}

const initMap = ({ selectedDots, highlightedBoxes, visualizationMode, tasksData, aislesMapped, scaleValue, pixelMatrix, canvasWidth, canvasHeight
	, groupingAlertsBy, aislesClientNames, categoriesOnMap, isDisabled = false, selectedAisle = null, rotated, obstacles = null }) => {
	return (ctx) => {
		let drawerReturn = {};
		storeMapDrawer(ctx, pixelMatrix, canvasWidth, canvasHeight, isDisabled);

		if (visualizationMode === 'ds_basic') {
			drawerReturn = groupDataDrawer(ctx, scaleValue, tasksData, groupingAlertsBy);
		} else if (visualizationMode === 'ds_aisle') {
			writeAisleOnCanvas(ctx, aislesMapped, aislesClientNames, scaleValue, false, true)
			drawerReturn = groupDataDrawer(ctx, scaleValue, tasksData, groupingAlertsBy);
		} else if (visualizationMode === 'ds_category') {
			categoryTextDrawer(ctx, categoriesOnMap)
			drawerReturn = groupDataDrawer(ctx, scaleValue, tasksData, groupingAlertsBy);
		} else if (visualizationMode === 'rs_basic') {
			// isTextOnMiddle is false when is in DS but when you are in RS must be true
			// canJoinAisleName is false when is in DS but when you are in RS must be true to show each aisle such as "A-2/A-1"
			writeAisleOnCanvas(ctx, aislesMapped, aislesClientNames, scaleValue, true, false)
			AislesDrawer({ ctx, aislesMapped, aislesClientNames, scaleValue });
		} else if (visualizationMode === 'ds_custom_aisle') {
			AislesDrawer({ ctx, aislesMapped, aislesClientNames, scaleValue, selectedAisle });
		} else if (visualizationMode === 'ds_nav_map') {
			writeAisleOnCanvas(ctx, aislesMapped, aislesClientNames, scaleValue, false, true)
			PosesDrawer(ctx, scaleValue, selectedDots, rotated);
			ObstaclesDrawer(ctx, scaleValue, obstacles, rotated);
			return drawerReturn;
		}

		highlightedBoxes.forEach(box => {
			const {
				pxInit,
				width,
				height,
				colorFill,
				strokeStyle, } = box;
			const [px, py] = pxInit;
			drawRectangleOnCanvas(ctx, px, py, width, height, colorFill, strokeStyle);
		});

		selectedDots.forEach(({ pixel, radius, colorFill }) => {
			CanvasDrawOnCircle(ctx, pixel, radius, colorFill);
		});

		return drawerReturn
	}
}

const handleCanvasClickDefault = (event) => {
	console.log('[Default] clickCoords: ', { x: event.clientX, y: event.clientY });
}

const handleCanvasHoverDefault = (canvasRef, event) => {
	// Ignore event to avoid annyoning console.logs of hover coords
}

export default function StoreMap(props) {
	const {
		selectedAisle,
		selectedDots = [],
		highlightedBoxes = [],
		storeMap, tasksData, categoriesOnMap, isDisabled,
		visualizationMode = 'basic', groupingAlertsBy = 'custom',
		handleCanvasClick = handleCanvasClickDefault, handleCanvasHover = handleCanvasHoverDefault,
		isSidenavOpen,
		isZoomActive = false,
	} = props;

	const canvasWidth = useState(storeMap.width)[0];
	const canvasHeight = useState(storeMap.height)[0];
	const [zoom, setZoom] = useState(1);
	const [start, setStart] = useState({ x: 0, y: 0 });
	const [panning, setPanning] = useState(false);
	const [points, setPoints] = useState({ x: 0, y: 0 });

	const containerRef = useRef(null);
	const minZoom = 0.9;
	const maxZoom = 4;

	const [canvasRef, drawerReturn] = useCanvas(initMap({
		selectedDots,
		highlightedBoxes,
		visualizationMode,
		tasksData, categoriesOnMap, isDisabled,
		canvasWidth, canvasHeight, groupingAlertsBy, aislesClientNames: storeMap.client_aisles_names,
		aislesMapped: storeMap.mapped_aisles, scaleValue: storeMap.scale_value, pixelMatrix: storeMap.image_bytes, 
		selectedAisle: selectedAisle, rotated: storeMap.rotated, obstacles: storeMap.obstacles,
	}));
	const zoomRef = useRef(null);

	const scaleMousePosition = (canvas, evt) => {
		const rect = canvas.getBoundingClientRect(); // Abs. size of element
		const scaleX = canvas.width / rect.width;    // Relationship bitmap vs. element for x
		const scaleY = canvas.height / rect.height;  // Relationship bitmap vs. element for y

		return {
			x: (evt.clientX - rect.left) * scaleX,   // Scale mouse coordinates after they have
			y: (evt.clientY - rect.top) * scaleY     // been adjusted to be relative to element
		}
	}

	const centerScroll = (x, y) => {
		const canvas = canvasRef.current;
		const zoomBox = zoomRef.current;
		const container = containerRef.current;
		if (canvas && zoomBox) {
			const newZoom = 3;
			const clientX = x * (newZoom * canvas.clientWidth) / canvasWidth;
			const clientY = y * (newZoom * canvas.clientHeight) / canvasHeight;

			container.scrollLeft = 0;
			container.scrollTop = 0;
			const newPointX = -clientX + zoomBox.clientWidth / 2;
			const newPointY = -clientY + zoomBox.clientHeight / 2;
			setZoom(newZoom);
			setPoints({
				x: newPointX,
				y: newPointY,
			});
			setTransform({ x: newPointX, y: newPointY, inputZoom: newZoom });

		}
	};

	const setTransform = ({ x: pointX, y: pointY, inputZoom = zoom }) => {
		canvasRef.current.style.transform = "translate(" + pointX + "px, " + pointY + "px) scale(" + inputZoom + ")";
	}

	const handleMouseHand = (event, mode) => {
		event.preventDefault();
		if (mode === 'down') {
			const [newX, newY] = [event.clientX - points.x, event.clientY - points.y];
			setStart({ x: newX, y: newY });
			setPanning(true);
		} else if (mode === 'up') {
			setPanning(false);
		} else if (mode === 'move' && panning) {
			const newX = event.clientX - start.x;
			const newY = event.clientY - start.y;
			setPoints({
				x: newX,
				y: newY
			});
			setTransform({ x: newX, y: newY });
		}
	}

	const handleZoom = (event) => {
		event.preventDefault();
		const [pointX, pointY] = [points.x, points.y];
		const {
			clientX,
			clientY,
		} = event;
		const delta = event.deltaY ? -event.deltaY : event.wheelDelta;
		const zoomFactor = 1.08;
		const newZoom = Math.min(
			Math.max(
				delta > 0 ? zoom * zoomFactor : zoom / zoomFactor, minZoom
			),
			maxZoom
		);

		// Calculate the position of the cursor relative to the element
		const rect = zoomRef.current.getBoundingClientRect();
		const offsetX = clientX - rect.left;
		const offsetY = clientY - rect.top;

		// Calculate the position of the cursor relative to the scaled image
		const cursorScaledX = (offsetX - pointX) / zoom;
		const cursorScaledY = (offsetY - pointY) / zoom;

		// Calculate the new pointX and pointY to keep the pixel under the cursor in place
		const newPointX = clientX - cursorScaledX * newZoom - rect.left;
		const newPointY = clientY - cursorScaledY * newZoom - rect.top;
		setPoints({
			x: newPointX,
			y: newPointY,
		});
		setZoom(newZoom);
		setTransform({ x: newPointX, y: newPointY });
	}

	const handleCenterMapOnAisle = (aisle) => {
		if (aisle && storeMap?.mapped_aisles) {
			const mappedAisles = storeMap.mapped_aisles;
			const aisleCoords = mappedAisles[aisle];
			if (aisleCoords) {
				const [clientXInit, clientYInit] = mappedAisles[aisle]?.px_init;
				const [clientXEnd, clientYEnd] = mappedAisles[aisle]?.px_end;
				const clientX = (clientXInit + clientXEnd) / 2;
				const clientY = (clientYInit + clientYEnd) / 2;
				centerScroll(clientX * storeMap.scale_value[0], clientY * storeMap.scale_value[1]);
			}
		}
	}

	useEffect(() => {
		let canvas = document.getElementById('responsive-canvas');
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;
	}, [canvasWidth, canvasHeight, isSidenavOpen])

	useEffect(() => {
		if (canvasRef?.current) {
			canvasRef.current.onmousemove = (event) => {
				const canvas = document.getElementById('responsive-canvas');
				const { x, y } = scaleMousePosition(canvas, event)
				handleCanvasHover({ clientX: x, clientY: y }, drawerReturn);
			}
		}
	}, [drawerReturn, canvasRef, handleCanvasHover]);

	useEffect(() => {
		if (zoomRef?.current) {
			zoomRef.current.onmousedown = (event) => {
				handleMouseHand(event, 'down');
			}
			zoomRef.current.onmouseup = (event) => {
				handleMouseHand(event, 'up');
			}
			zoomRef.current.onwheel = (event) => {
				handleZoom(event);
			}
			zoomRef.current.onmousemove = (event) => {
				handleMouseHand(event, 'move');
			}
		}
	}, [zoomRef.current, handleMouseHand]);

	useEffect(() => {
		if (selectedAisle && storeMap && storeMap.mapped_aisles) {
			handleCenterMapOnAisle(selectedAisle);
		}
	}, [selectedAisle, containerRef.current]);

	useEffect(() => {
		if (selectedDots) {
			if (selectedAisle?.length > 0) {
				handleCenterMapOnAisle(selectedAisle);
			}
		}
	}, [selectedDots]);

	return (
		isZoomActive ?
			<div
				ref={containerRef}
				style={{
					position: 'relative',
					overflow: 'auto',
					width: '100%',
					minWidth: '20em',
					maxWidth: '30em',
					maxHeight: '23em',
					height: 'auto',
				}}>
				<Box ref={zoomRef} sx={{
					width: '100%',
					height: '100%',
					cursor: 'grab',
					position: 'static',
				}}>
					<Suspense fallback={<LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />}>
						<canvas
							id="responsive-canvas"
							ref={canvasRef}
							tabIndex="1"
							onClick={handleCanvasClick}
							style={{
								outline: 'none',
								position: 'relative',
								transformOrigin: '0px 0px',
								width: '100%',
								height: 'auto',
							}}
						/>
					</Suspense>
				</Box>
			</div>
			:
			<Suspense fallback={<LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />}>
				<canvas id="responsive-canvas" ref={canvasRef} tabIndex="1" onClick={handleCanvasClick} style={{ "outline": "none", width: '100%' }} />
			</Suspense>
	);
};
