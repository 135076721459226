import { createReducer } from './utils';
import { PdfExporter } from '../services/pdfExporter';
import { InvExporter } from '../services/invExporter';
import { ExcelExporter } from '../services/excelExporter';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'
import {
    SNACK_SET,
    DIGITAL_STORE_REQUEST,
    DIGITAL_STORE_AVAILABLE,
    DIGITAL_STORE_UNAVAILABLE,
    DIGITAL_STORE_AVAILABLE_PDF,
    DIGITAL_STORE_AVAILABLE_INV,
    DIGITAL_STORE_AVAILABLE_EXCEL,
    DIGITAL_STORE_AVAILABLE_DATED_EXCEL,
} from './constants';

const defaultState = {
    loadingMapInfo: false,
    storeMapInfo: {},
    aisleThumbnail: {},
    loadingAisleThumbnail: false,
    loadingExcelByDate: false,
    isLoadingMaps: false,
    storeMaps: [],
    resumeDStore: {},
    loadingResumeDStore: false,
    connectionTime: [],
    loadingConnectionTime: false,
    sessionData: [],
    loadingSessionData: false,
};

// Reducer

export const reducer = createReducer(defaultState, {
    [DIGITAL_STORE_REQUEST]: handleRequest,
    [DIGITAL_STORE_AVAILABLE]: handleAvailable,
    [DIGITAL_STORE_UNAVAILABLE]: handleUnavailable,
    [DIGITAL_STORE_AVAILABLE_PDF]: handleAvailablePdf,
    [DIGITAL_STORE_AVAILABLE_INV]: handleAvailableInv,
    [DIGITAL_STORE_AVAILABLE_EXCEL]: handleAvailableExcel,
    [DIGITAL_STORE_AVAILABLE_DATED_EXCEL]: handleAvailableDatedExcel,
});

function handleAvailablePdf(state, { payload: { loadingName, pdfFile, pdfType } }) {
    const newState = {
        ...state,
        [loadingName]: false,
    };
    if (pdfFile) {
        const pdfExporter = new PdfExporter()
        pdfExporter.handleDownloadPdf(pdfFile, pdfType)
    } else {
        // TODO: traducir estooooo!!!!!
        alert("PDF not available")
    }
    return newState;
}


function handleAvailableInv(state, { payload: { loadingName, invFile } }) {
    const newState = {
        ...state,
        [loadingName]: false,
    };
    if (invFile) {
        const invExporter = new InvExporter()
        invExporter.handleDownloadInv(invFile)
    } else {
        // TODO: traducir estooooo!!!!!
        alert("INV not available")
    }
    return newState;
}


function handleAvailableExcel(state, { payload: { loadingName, excelData, excelFileName, canFormatData = false, excelDate = null } }) {
    const newState = {
        ...state,
        [loadingName]: false,
    };

    const excelExporter = new ExcelExporter();
    excelExporter.exportAsExcelFile({ excelData, excelFileName, canFormatData, excelDate })

    return newState
}

function handleAvailableDatedExcel(state, { payload: { data, loadingName, fileName, excelColumns, aislesClientNames, getChainMeaning, t, chain, canFormatData = false, excelDate } }) {
    const newState = {
        ...state,
        [loadingName]: false,
    };

    if (!excelColumns.includes('creation_timestamp')) {
        excelColumns = excelColumns.concat('creation_timestamp')
    }

    // Translate the value in task_type
    if (excelColumns.includes('task_type')) {
        data = data.map(task => {
            return { ...task, task_type: getChainMeaning(t, chain, task?.task_type, task?.task_type) }
        });
    }

    // Convert the data from an array of dicts to an array of arrays
    let parsedData = data.map(task => excelColumns.map(column => task[column]));

    // Translate aisle name
    if (excelColumns.includes("aisle")) {
        const aisleIndex = excelColumns.indexOf("aisle")
        parsedData?.forEach(parsedData => {
            const aislesClientName = aislesClientNames[parsedData[aisleIndex]];
            parsedData[aisleIndex] = aislesClientName ? aislesClientNames[parsedData[aisleIndex]] : parsedData[aisleIndex];
        })
    }

    const translatedColumns = excelColumns?.map(column => getChainMeaning(t, chain, column));

    const excelExporter = new ExcelExporter();
    excelExporter.exportAsExcelFile({ excelData: [translatedColumns, ...parsedData], excelFileName: fileName, canFormatData, excelDate })

    return newState
}

export function getDSInfo(form) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingMapInfo'
        const keyState = 'storeMapInfo'
        const err_msg = 'There was an error getting the store information';

        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName } });
        try {
            const storeMapResponse = await dataSource.getDSInfo(form);
            const storeMapInfo = storeMapResponse ? storeMapResponse : {}
            dispatch({
                type: DIGITAL_STORE_AVAILABLE,
                payload: { keyState, data: storeMapInfo, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { keyState, loadingName, defaultState: {} },
            });
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { keyState: 'resumeDStore', loadingName: 'loadingResumeDStore', defaultState: {} },
            });
            const snack = {
                open: true,
                message: error?.message ? err_msg + ': ' + error.message : err_msg,
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getStoreMaps(form) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isLoadingMaps'
        const keyState = 'storeMaps'
        const err_msg = 'There was an error getting the store information';

        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName } });
        try {
            const storeMapResponse = await dataSource.getStoreMaps(form);
            const storeMaps = storeMapResponse ? storeMapResponse : []
            storeMaps.forEach(row => {
                row['store_id'] = form.store_id
            });
            dispatch({
                type: DIGITAL_STORE_AVAILABLE,
                payload: { keyState, data: storeMaps, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { keyState, loadingName, defaultState: {} },
            });
            const snack = {
                open: true,
                message: error?.message ? err_msg + ': ' + error.message : err_msg,
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}


export function getDigitalStorePdf(form) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isPdfLoading'
        const keyState = 'pdfFileMessage'

        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.getDigitalStorePdf(form);
            const pdfType = response?.pdf_list ? 'listFormat' : 'bytesFormat'; // robotNavigationData come formated from backend as list
            const pdfFile = pdfType === 'listFormat' ? response.pdf_list : response ? response : null;

            dispatch({
                type: DIGITAL_STORE_AVAILABLE_PDF,
                payload: { loadingName, pdfFile: pdfFile, pdfType: pdfType },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { error, keyState, loadingName, defaultState: { 'message': 'error' } },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}


export function getDigitalStoreInv(form) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isInvLoading'
        const keyState = 'invFileMessage'

        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName } });
        try {
            const invFile = await dataSource.getDigitalStoreInv(form);
            dispatch({
                type: DIGITAL_STORE_AVAILABLE_INV,
                payload: { loadingName, invFile },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { error, keyState, loadingName, defaultState: { 'message': 'error' } },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}


export function getDigitalStoreExcel({ excelData, excelFileName, excelDate }) {
    return async (dispatch, _, { services: { __ } }) => {
        const loadingName = 'isExcelLoading'
        const keyState = 'excelFileMessage'

        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName } });
        try {
            dispatch({
                type: DIGITAL_STORE_AVAILABLE_EXCEL,
                payload: { loadingName, excelData, excelFileName, excelDate },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { error, keyState, loadingName, defaultState: { 'message': 'error' } },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}


export function getAisleThumbnail(client_id, store_id, aisle, session) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'aisleThumbnail'
        const loadingName = 'loadingAisleThumbnail'
        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getAisleThumbnail(client_id, store_id, aisle, session);
            dispatch({
                type: DIGITAL_STORE_AVAILABLE,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the aisle picture.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getExcelByDate(form, fileName, excelColumns, aislesClientNames, getChainMeaning, t, chain) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'excelByDate'
        const loadingName = 'loadingExcelByDate'
        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getExcelByDate(form);

            // Fetch the data from the signed url from Cloud Storage
            const signedURLResponse = await fetch(response?.signed_url);
            const responseJSON = await signedURLResponse.json()

            dispatch({
                type: DIGITAL_STORE_AVAILABLE_DATED_EXCEL,
                payload: { data: responseJSON?.data, loadingName, fileName, excelColumns, aislesClientNames, getChainMeaning, t, chain },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: 'error' },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the data for the excel download.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getResumeDStore({ taskData, taskType, taskGrouping }) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingResumeDStore'
        const keyState = 'resumeDStore'

        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.getResumeDStore(taskData, taskType, JSON.stringify(taskGrouping));
            dispatch({
                type: DIGITAL_STORE_AVAILABLE,
                payload: { keyState, data: response?.resume_bi_data ?? [], loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { keyState, loadingName, defaultState: {} },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the store information',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getDSConnectionTime({ storeCode, multirobotId }) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingConnectionTime'
        const keyState = 'connectionTime'

        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.getDSConnectionTime(storeCode, multirobotId);
            dispatch({
                type: DIGITAL_STORE_AVAILABLE,
                payload: { keyState, data: response ?? [], loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { keyState, loadingName, defaultState: {} },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the connection time',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getDSSessionData({ session }) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingSessionData'
        const keyState = 'sessionData'

        dispatch({ type: DIGITAL_STORE_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.getDSSessionData(session);
            dispatch({
                type: DIGITAL_STORE_AVAILABLE,
                payload: { keyState, data: response ?? [], loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: DIGITAL_STORE_UNAVAILABLE,
                payload: { keyState, loadingName, defaultState: {} },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the session data',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}